import httpUtil from "@/utils/httpUtil";

/** 新增/编辑邮箱 */
export function editEmail(params) {
	return httpUtil.post("/company/editEmail", params)
}

/** 邮箱列表 */
export function emailList(params) {
	return httpUtil.post("/company/emailList", params)
}


/** 删除邮箱 */
export function delEmail(params) {
	return httpUtil.post("/company/delEmail", params)
}


/** 导入邮箱 */
export function importRecord(params) {
	return httpUtil.post("/company/importRecord", params)
}


/** 发送邮件 */
export function sendEmail(params) {
	return httpUtil.post("/company/sendEmail", params)
}


/** 一件发送邮件 */
export function clickPublish(params) {
	return httpUtil.get("/company/clickPublish", params)
}

/** 获取客户列表数据 */
export function getCustomerPC(params,type) {
	return httpUtil.get("/customer/list", params, type)
}












