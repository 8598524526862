<template>
	<div>
		<div class="btns">
			<el-button type="primary" @click="showDialog('add')">添加邮箱</el-button>
			<!-- <el-upload class="upload-demo" :action="uploadUrl" :on-change="handleChange" :on-remove="handleRemove"
				:limit="limitUpload" :show-file-list="false"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
				:auto-upload="false">
				<el-button type="primary">导入excel</el-button>
			</el-upload> -->

			<el-upload ref='upload' class="upload-demo myUpload" :action="uploadUrl" :data="upData"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
				:on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="1"
				:on-exceed="handleExceed" :on-success="handleSuccess" :on-error="handleError" :show-file-list="false">
				<el-button size="small" type="primary">导入excel</el-button>
			</el-upload>

			<el-button type="primary" @click="sendMail">一键发送</el-button>
		</div>
		<div class="tableBox">
			<commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
				@handleCurrentChange="handleCurrentChange" :total="total">
				<template v-slot:table>
					<el-table-column prop="name" align="center" label="姓名" width="200">
					</el-table-column>
					<el-table-column prop="email" label="邮箱地址" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="success" label="发送成功次数" align="center" width="200">
					</el-table-column>
					<el-table-column prop="error" label="发送失败次数" align="center" width="200">
					</el-table-column>
					<el-table-column label="操作" width="300" align="center">
						<template slot-scope="scope">
							<el-button @click="showDialog('edit', scope.row)" type="text" style="color: #409eff;">编辑</el-button>
							<el-popconfirm v-if="scope.row.success == 0" @confirm="fsMail(scope.row)" title="确定单独发送邮件？">
								<el-button slot="reference" type="text" style="color: #409eff;margin-left: 10px;">发送</el-button>
							</el-popconfirm>
							<!-- <el-button @click="fsMail(scope.row)" type="text" style="color: #409eff;">发送</el-button> -->
							<el-popconfirm @confirm="delMail(scope.row)" title="确定要删除这条信息吗？">
								<el-button slot="reference" type="text" style="color: red;margin-left: 10px;">删除</el-button>
							</el-popconfirm>

						</template>
					</el-table-column>
				</template>
			</commonTable>
			<!-- <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange">
                
            </el-table> -->
		</div>

		<el-dialog title="" :visible.sync="dialogFormVisible" :append-to-body="true" @close="closeDialog">
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="邮箱地址" :label-width="formLabelWidth" prop="email">
					<el-input v-model="form.email" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button type="primary" @click="setMail('form')">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :append-to-body="true">
			<span>此用户已经发送过邮件，确认要再次发送吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="configFs">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import XLSX from 'xlsx';
	import commonTable from "@/components/common/commonTable";
	import {
		editEmail,
		emailList,
		delEmail,
		importRecord,
		sendEmail,
		clickPublish
	} from '@/api/mail.js'
	let baseUrl = process.env.VUE_APP_API_URL
	export default {
		data() {
			var isEmail = (rule, value, callback) => {
				if (!value) {
					callback();
				} else {
					const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
					const email = reg.test(value);
					if (!email) {
						callback(new Error("邮箱格式如:admin@163.com"));
					} else {
						callback();
					}
				}
			};
			return {
				dialogVisible: false,
				dialogFormVisible: false,
				limitUpload: 1,
				tableData: [],
				multipleSelection: [],
				form: {
					name: '',
					email: '',
					companyId: null
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					email: [{
							required: true,
							message: "请输入邮箱",
							trigger: "blur"
						},
						{
							validator: isEmail,
							trigger: "blur"
						}
					],
				},
				upData: {
					companyId: null
				},
				companyId: null,
				total: 0,
				formLabelWidth: '120px',
				pageNum: 1,
				pageSize: 10,
				loading: false,
				uploadUrl: baseUrl + '/company/importRecord',
				emailId: null,
				row: null
			}
		},
		components: {
			commonTable
		},
		mounted() {
			var info = JSON.parse(localStorage.getItem('info'))
			this.companyId = info.companyId
			this.upData.companyId = info.companyId
			this.init()
		},
		methods: {
			closeDialog() {
				this.dialogFormVisible = false
				this.form = {
					name: '',
					email: '',
					companyId: null
				}
			},
			showDialog(type, data) {
				console.log(data)
				this.type = type
				if (type == 'edit') {
					this.emailId = data.emailId
					this.form = data
				}
				this.dialogFormVisible = true
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			handleExceed(files, fileList) {

			},
			beforeRemove(file, fileList) {

			},
			handleSuccess(response, file, fileList) {
				this.$refs.upload.clearFiles()
				console.log(response)
				if (response.code == 200) {
					this.init()
					this.$message.success('导入成功！')
				} else {
					this.$message.warning(response.message)
				}

			},
			handleError(err, file, fileList) {
				console.log(err)
			},

			delMail(data) {
				delEmail({
					emailId: data.emailId
				}).then(res => {
					console.log(res)
					if (res.code == 200) {
						this.init()
						this.$message.success('删除成功！')
					} else {
						this.$message.warning(res.message)
					}
				})

			},
			handleSizeChange(val) {
				console.log(val)
				this.pageNum = val;
				this.init()
			},
			handleCurrentChange(val) {
				console.log(val)
				this.pageNum = val;
				this.init()
			},
			init() {
				emailList({
					companyId: this.companyId,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}).then(res => {
					console.log(res)
					if(res.data && res.data.pageInfo && res.data.pageInfo.list) {
						this.tableData = res.data.pageInfo.list
						this.total = res.data.pageInfo.total
					}else {
						this.tableData = []
					}
					
				})
			},
			setMail(formName) {
				// if(this.type = 'edit') {
				// 	this.form.emailId = this.emailId
				// }
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.form.companyId = this.companyId
						editEmail(this.form).then(res => {
							this.dialogFormVisible = false
							console.log(res)
							if (res.code == 200) {
								this.init()
								if (this.type = 'edit') {
									this.$message.success('编辑成功！')
								} else {
									this.$message.success('添加成功！')
								}

							} else {
								this.$message.warning(res.message)
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},
			importfxx(obj) {
				let _this = this;
				// 通过DOM取文件数据
				this.file = obj
				var rABS = false; //是否将文件读取为二进制字符串
				var f = this.file;
				var reader = new FileReader();
				//if (!FileReader.prototype.readAsBinaryString) {
				FileReader.prototype.readAsBinaryString = function(f) {
					var binary = "";
					var rABS = false; //是否将文件读取为二进制字符串
					var pt = this;
					var wb; //读取完成的数据
					var outdata;
					var reader = new FileReader();
					reader.onload = function(e) {
						var bytes = new Uint8Array(reader.result);
						var length = bytes.byteLength;
						for (var i = 0; i < length; i++) {
							binary += String.fromCharCode(bytes[i]);
						}
						var XLSX = require('xlsx');
						if (rABS) {
							wb = XLSX.read(btoa(fixdata(binary)), { //手动转化
								type: 'base64'
							});
						} else {
							wb = XLSX.read(binary, {
								type: 'binary'
							});
						}
						outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); //outdata就是你想要的东西
						this.da = [...outdata]
						let arr = []
						this.da.map(v => {
							let obj = {}
							obj.name = v['APP名称']
							obj.address = v['APPID']
							arr.push(obj)
						})
						console.log(arr)
						_this.tableData = _this.tableData.concat(arr)
						console.log(_this.tableData)
						// return arr
					}
					reader.readAsArrayBuffer(f);
				}

				if (rABS) {
					reader.readAsArrayBuffer(f);
				} else {
					reader.readAsBinaryString(f);
				}
			},
			handleChange(file, fileList) {
				this.fileTemp = file.raw
				if (this.fileTemp) {
					if ((this.fileTemp.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (this
							.fileTemp.type == 'application/vnd.ms-excel')) {
						console.log(this.fileTemp)
						// this.importfxx(this.fileTemp)
					} else {
						this.$message({
							type: 'warning',
							message: '附件格式错误，请删除后重新上传！'
						})
					}
				} else {
					this.$message({
						type: 'warning',
						message: '请上传附件！'
					})
				}
			},

			// handleRemove(file, fileList) {
			// 	this.fileTemp = null
			// },
			fsMail(row) {
				console.log(row)
				this.row = row
				this.getIds([row.emailId])
				// if (row.success > 1) {
				// 	this.dialogVisible = true
				// }
			},
			configFs() {
				this.getIds([this.row.emailId])
			},
			sendMail() {
				const loading = this.$loading({
					lock: true,
					text: '邮件发送中！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				clickPublish({
					companyId: this.companyId,
				}).then(res => {
					
					if (res.code == 200 && res.data.error == 0) {
						loading.close();
						this.$message.success('发送成功！')
					} else {
						loading.close();
						this.$message.warning('发送失败！')
					}
					this.init()
					
				}).catch(err=> {
					loading.close();
				})
			},
			getIds(ids) {
				const loading = this.$loading({
					lock: true,
					text: '邮件发送中！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				sendEmail({
					ids
				}).then(res => {
					if (res.code == 200 && res.data.error == 0) {
						loading.close();
						this.$message.success('发送成功！')
					} else {
						loading.close();
						this.$message.warning('发送失败！')
					}
					this.init()
					
				}).catch(err=> {
					loading.close();
				})
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		}
	}
</script>
<style scoped>
	.btns,
	.tableBox {
		width: 100%;
		box-sizing: border-box;
		padding: 30px 20px;
		background: #fff;
	}

	.btns {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.tableBox {
		margin-top: 20px;
	}

	/deep/ .el-upload--text {
		width: 88px;
		height: 34px;
		border: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px;
	}
</style>